/* src/index.css */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white; /* Set background color to white */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.menu-item {
  color: #00418D;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-weight: 400; /* Regular weight */
  font-size: 20px;
  cursor: pointer;
}

.menu-item:hover {
  color: black;
}

/* New CSS for desktop view */
@media (min-width: 769px) {
  .blog-image {
    width: 90% !important; /* Reduce image size by 25% */
    height: auto !important;
  }

  .slick-slide {
    display: flex !important;
    justify-content: center;
  }
}

/* Blog page styles */
.blog-page {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.blog-page-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 500; /* Medium weight */
  font-size: 32px;
  color: #00418D;
  margin-bottom: 1rem;
}

.blog-page-content {
  font-family: 'Source Serif Pro', serif;
  font-weight: 300; /* Light weight */
  font-size: 18px;
  color: #464646;
}

/* Global styles */
.lazy {
  opacity: 0;
}

.lazy-loaded {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

/* Custom font styles for SketchX */
.sketchx-font {
  font-family: 'Inter', sans-serif;
  font-weight: 300; /* Light weight */
  font-style: italic;
}
